import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';

interface IProps {
  title: string;
  items: any[];
}

export const HeaderItemPopout = (props: IProps) => {
  return (
    <Popover className="relative">
      <PopoverButton className="text-md flex items-center gap-x-1 leading-6">
        {props.title}
        <ChevronDownIcon
          aria-hidden="true"
          className="h-5 w-5 flex-none text-gray-400"
        />
      </PopoverButton>

      <PopoverPanel
        transition
        className="data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in absolute -left-8 top-full z-[1000] mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5 transition"
      >
        <div className="p-4">
          {props.items.map((item) => (
            <div
              key={item.name}
              className="text-md group relative flex items-center gap-x-6 rounded-lg p-4 leading-6 hover:bg-gray-50"
            >
              <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                {item.iconComponent && item.iconComponent}
                {item.icon && (
                  <item.icon
                    aria-hidden="true"
                    className="h-6 w-6 text-gray-600 group-hover:text-primary"
                  />
                )}
              </div>
              <div className="flex-auto">
                <Link
                  href={item.href}
                  className="block cursor-pointer font-semibold text-gray-900">

                  {item.name}
                  <span className="absolute inset-0" />

                </Link>
                <p className="mt-1 text-gray-600">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </PopoverPanel>
    </Popover>
  );
};
