import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel
} from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import Link from 'next/link';

interface IProps {
  title: string;
  items: any[];
}

export const MobileItemDisclosure = (props: IProps) => {
  return (
    <Disclosure as="div" className="-mx-3">
      <DisclosureButton className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
        {props.title}
        <ChevronDownIcon
          aria-hidden="true"
          className="group-data-[open]:rotate-180 h-5 w-5 flex-none"
        />
      </DisclosureButton>
      <DisclosurePanel className="mt-2 space-y-2">
        {[...props.items].map((item) => (
          <Link href={item.href} legacyBehavior>
            <DisclosureButton
              key={item.name}
              as="a"
              className="text-md block cursor-pointer rounded-lg py-2 pl-6 pr-3 font-semibold leading-7 text-gray-900 hover:bg-gray-50"
            >
              {item.name}
            </DisclosureButton>
          </Link>
        ))}
      </DisclosurePanel>
    </Disclosure>
  );
};
