import { useAuth } from '@/app/context/AuthContext/useAuth';
import { useRouter } from 'next/router';
import Link from 'next/link';
import React from 'react';

export default function LogoTitle({ hideLogo, onClickRoute }) {
  const router = useRouter();

  return (
    <Link href={onClickRoute} passHref legacyBehavior>
      <span className="flex cursor-pointer items-center pl-1 md:pl-3">
        {!hideLogo && (
          <img
            className="hidden md:flex"
            src="/white-logo.png"
            width={40}
            height={40}
            alt={'Limbiks logo'}
            style={{ transform: 'scaleX(1)' }}
          />
        )}

        <div
          className={`toggleColour pl-3 text-2xl tracking-widest text-base-content no-underline hover:no-underline ${
            hideLogo ? 'lg:text-4xl' : ''
          }`}
        >
          LIMBIKS
        </div>
      </span>
    </Link>
  );
}
