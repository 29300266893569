import React, { useEffect, useState } from 'react';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { useAuth } from '@/app/context/AuthContext/useAuth';
import { useRouter } from 'next/router';
import { useModal } from '@/app/context/ModalContext/useModal';
import LogoTitle from '../../common/logo-title/LogoTitle';
import { useCustomer } from '@/app/context/CustomerContext/useCustomer';
import { useDeck } from '@/app/context/DeckContext/useDeck';
import { useAnalytics } from '@/app/context/AnalyticsContext/useAnalytics';

export default function AuthenticatedHeader({ children }) {
  const { user, signOut } = useAuth();
  const router = useRouter();
  const { openModal } = useModal();
  const { trackEvent, analyticsInstance } = useAnalytics();

  const { subscription, logCheckoutSession } = useCustomer();

  const { cardSchedulesDue } = useDeck();

  const onPracticeTests = router.route.includes('app/practice');

  useEffect(() => {
    if (router.query && router.query.action === 'purchase') {
      trackEvent(analyticsInstance, 'account_subscription_created');
      if (router.query.session_id) {
        logCheckoutSession(router.query.session_id as string, true);
      }
      router.replace(window.location.pathname, undefined, { shallow: true });
      openModal('upgradeSuccess');
    }

    if (router.query && router.query.action === 'cancel') {
      if (router.query.session_id) {
        logCheckoutSession(router.query.session_id as string, false);
      }
      router.replace(window.location.pathname, undefined, { shallow: true });
    }
  }, []);

  return (
    <div className="drawer bg-base-100 text-base-content">
      <input id="my-drawer-3" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content flex flex-col">
        {/* Navbar */}
        <div
          style={{ zIndex: 998 }}
          className="navbar fixed top-0 w-full border-b border-gray-600 bg-base-100 "
        >
          <div className="flex-none lg:hidden">
            <label htmlFor="my-drawer-3" className="btn btn-ghost btn-square">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                className="inline-block h-6 w-6 stroke-current"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                ></path>
              </svg>
            </label>
          </div>
          <div className="mx-2 flex-1 px-2">
            <LogoTitle hideLogo onClickRoute={'/app'} />
            {/* <div className="badge bg-base-100">PRO</div> */}
            <div
              onClick={() => router.push('/app/account')}
              className="badge cursor-pointer bg-base-100 text-base-content"
            >
              {subscription.tag}
            </div>

            {/* {
              <div className="ml-2 hidden text-ellipsis rounded bg-gray-500 p-1 pl-2 pr-2 text-sm md:block">
                {
                  'A google authentication issue may result in failure to load data'
                }
                <span className="pl-5">
                  <a
                    href="https://status.firebase.google.com"
                    target="_blank"
                    className="link"
                  >
                    Click here to check status
                  </a>
                </span>
              </div>
            } */}
          </div>
          <div className="hidden flex-none lg:block">
            <ul className="menu menu-horizontal">
              {/* Navbar menu content here */}

              <li
                onClick={() => {
                  trackEvent(analyticsInstance, 'app_header_click_generate');
                  router.push('/app');
                }}
                className="text-base-content"
              >
                <div className="tabs">
                  <a
                    className={`${
                      router.route.includes('app/practice') ||
                      router.route.includes('app/memorize')
                        ? 'text-base-content'
                        : 'bg-neutral text-neutral-content'
                    } tab tab-bordered bordered rounded border border-white `}
                  >
                    Generate
                  </a>
                  {/* <a className="tab tab-bordered border-gray-600 text-base-content">
                    Memorize
                  </a> */}
                  {/* <a className="tab tab-bordered border-gray-600  text-base-content">
                    Practice Tests
                  </a> */}
                </div>
              </li>
              <li
                onClick={() => {
                  trackEvent(analyticsInstance, 'app_header_click_memorize');
                  router.push('/app/memorize');
                }}
                className="text-base-content"
              >
                <div className="tabs">
                  <div className="indicator">
                    {/* {cardSchedulesDue.length > 0 && (
                      <span className="badge indicator-item">
                        {cardSchedulesDue.length}
                      </span>
                    )} */}

                    <a
                      className={`${
                        router.route.includes('app/memorize')
                          ? 'bg-neutral text-neutral-content'
                          : 'text-base-content'
                      } tab tab-bordered bordered rounded border border-white `}
                    >
                      Memorize
                    </a>
                  </div>
                </div>
              </li>
              <li
                onClick={() => {
                  trackEvent(analyticsInstance, 'app_header_click_practice');
                  router.push('/app/practice');
                }}
                className="text-base-content"
              >
                <div className="tabs">
                  <a
                    className={`${
                      router.route.includes('app/practice')
                        ? 'bg-neutral text-neutral-content'
                        : 'text-base-content'
                    } tab tab-bordered bordered rounded border border-white `}
                  >
                    Practice
                  </a>
                </div>
              </li>
              {
                // <li>
                //   <div
                //     data-tip="Follow Limbiks on instagram to stay up to date with the latest features"
                //     className="tooltip tooltip-bottom p-0"
                //   >
                //     <div
                //       onClick={() => router.push('/app/practiceTests')}
                //       tabIndex={0}
                //       className="btn btn-ghost rounded-btn flex gap-3"
                //     >
                //       <svg
                //         xmlns="http://www.w3.org/2000/svg"
                //         viewBox="0 0 24 24"
                //         fill="white"
                //         height={24}
                //         width={24}
                //       >
                //         <path
                //           fill="white"
                //           d="M17.8492 11.8051L17.1421 11.098L7.24264 20.9975H3V16.7549L14.3137 5.44114L19.9706 11.098C20.3611 11.4885 20.3611 12.1217 19.9706 12.5122L12.8995 19.5833L11.4853 18.1691L17.8492 11.8051ZM18.5563 2.61272L21.3848 5.44114C21.7753 5.83167 21.7753 6.46483 21.3848 6.85536L19.9706 8.26957L15.7279 4.02693L17.1421 2.61272C17.5327 2.22219 18.1658 2.22219 18.5563 2.61272Z"
                //         ></path>
                //       </svg>
                //       Practice Tests
                //     </div>
                //   </div>
                // </li>
                /* <li>
                <div
                  onClick={openFeedback}
                  data-tip="Give Feedback"
                  className="tooltip tooltip-bottom"
                >
                  <a>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="white"
                        d="M6.455 19L2 22.5V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H6.455zM4 18.385L5.763 17H20V5H4v13.385zM11 13h2v2h-2v-2zm0-6h2v5h-2V7z"
                      />
                    </svg>
                  </a>
                </div>
              </li>
              <li>
                <div
                  onClick={openHelp}
                  data-tip="Help"
                  className="tooltip tooltip-bottom"
                >
                  <a>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width="24"
                      height="24"
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path
                        fill="white"
                        d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
                      />
                    </svg>
                  </a>
                </div>
              </li>
              <li>
                <div
                  onClick={() => router.push('/app/account')}
                  data-tip="My Account"
                  className="tooltip tooltip-bottom"
                >
                  <a>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="white"
                        d="M12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2ZM12.1597 16C10.1243 16 8.29182 16.8687 7.01276 18.2556C8.38039 19.3474 10.114 20 12 20C13.9695 20 15.7727 19.2883 17.1666 18.1081C15.8956 16.8074 14.1219 16 12.1597 16ZM12 4C7.58172 4 4 7.58172 4 12C4 13.8106 4.6015 15.4807 5.61557 16.8214C7.25639 15.0841 9.58144 14 12.1597 14C14.6441 14 16.8933 15.0066 18.5218 16.6342C19.4526 15.3267 20 13.7273 20 12C20 7.58172 16.4183 4 12 4ZM12 5C14.2091 5 16 6.79086 16 9C16 11.2091 14.2091 13 12 13C9.79086 13 8 11.2091 8 9C8 6.79086 9.79086 5 12 5ZM12 7C10.8954 7 10 7.89543 10 9C10 10.1046 10.8954 11 12 11C13.1046 11 14 10.1046 14 9C14 7.89543 13.1046 7 12 7Z"
                      ></path>
                    </svg>
                  </a>
                </div>
              </li> */
              }
              <li>
                <div className="p-0">
                  <div className="dropdown-end dropdown">
                    <div tabIndex={0} className="btn btn-ghost rounded-btn">
                      {user?.email}
                    </div>
                    <ul
                      tabIndex={0}
                      className="dropdown-content menu bg-neutral p-2 text-neutral-content shadow"
                    >
                      <li className="w-full">
                        <a
                          onClick={() => {
                            trackEvent(
                              analyticsInstance,
                              'app_header_click_feedback'
                            );
                            openModal('feedback');
                          }}
                        >
                          Feedback
                        </a>
                      </li>
                      <li className="w-full">
                        <a
                          onClick={() => {
                            trackEvent(
                              analyticsInstance,
                              'app_header_click_help'
                            );
                            openModal('help');
                          }}
                        >
                          Help
                        </a>
                      </li>
                      <li className="w-full">
                        <a
                          onClick={() => {
                            trackEvent(
                              analyticsInstance,
                              'app_header_click_settings'
                            );
                            openModal('settings');
                          }}
                        >
                          Settings
                        </a>
                      </li>
                      <li className="w-full">
                        <a
                          onClick={() => {
                            trackEvent(
                              analyticsInstance,
                              'app_header_click_account'
                            );
                            router.push('/app/account');
                          }}
                        >
                          My Account
                        </a>
                      </li>
                      <li className="w-full">
                        <a
                          onClick={() => {
                            trackEvent(
                              analyticsInstance,
                              'app_header_click_sign_out'
                            );
                            signOut();
                          }}
                        >
                          Sign Out
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* <a onClick={signOut}>Sign Out</a> */}
                {/* <div className="dropdown-end dropdown">
                  <div tabIndex={0} className="btn btn-ghost rounded-btn">
                    {user?.email}
                  </div>
                  <ul
                    tabIndex={0}
                    className="dropdown-content menu rounded-box w-52 bg-gray-500 p-2 shadow"
                  >
                    <li>
                      <a onClick={signOut}>Sign Out</a>
                    </li>
                  </ul>
                </div> */}
              </li>
            </ul>
          </div>
        </div>
        {children}
      </div>
      <div className="drawer-side">
        <label htmlFor="my-drawer-3" className="drawer-overlay"></label>
        <ul className="menu h-full w-80 bg-base-100 p-4 text-base-content">
          {/* Sidebar content here */}

          <li
            className={`${
              router.route.includes('app/practice') ||
              router.route.includes('app/memorize') ||
              router.route.includes('app/account')
                ? ''
                : 'bg-neutral text-neutral-content'
            } rounded"`}
          >
            <a
              onClick={() => {
                trackEvent(analyticsInstance, 'app_sidebar_click_generate');
                router.push('/app');
              }}
            >
              Generate
            </a>
          </li>
          <li
            className={`${
              router.route.includes('app/memorize')
                ? 'bg-neutral text-neutral-content'
                : ''
            } rounded"`}
          >
            <a
              onClick={() => {
                trackEvent(analyticsInstance, 'app_sidebar_click_memorize');
                router.push('/app/memorize');
              }}
            >
              Memorize
            </a>
          </li>
          <li
            className={`${
              router.route.includes('app/practice')
                ? 'bg-neutral text-neutral-content'
                : ''
            } rounded"`}
          >
            <a
              onClick={() => {
                trackEvent(analyticsInstance, 'app_sidebar_click_practice');
                router.push('/app/practice');
              }}
            >
              Practice
            </a>
          </li>
          <li className="border-t-2 border-secondary">
            {/* className="border-t-2 border-secondary" */}
            <a
              onClick={() => {
                trackEvent(analyticsInstance, 'app_sidebar_click_feedback');
                openModal('feedback');
              }}
            >
              Feedback
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                trackEvent(analyticsInstance, 'app_sidebar_click_help');
                openModal('help');
              }}
            >
              Help
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                trackEvent(analyticsInstance, 'app_sidebar_click_settings');
                openModal('settings');
              }}
            >
              Settings
            </a>
          </li>
          <li
            className={`${
              router.route.includes('app/account')
                ? 'bg-neutral text-neutral-content'
                : ''
            } rounded"`}
          >
            <a
              onClick={() => {
                trackEvent(analyticsInstance, 'app_sidebar_click_account');
                router.push('/app/account');
              }}
            >
              My Account
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                trackEvent(analyticsInstance, 'app_sidebar_click_sign_out');
                signOut();
              }}
            >
              Sign Out
            </a>
          </li>
        </ul>
      </div>
    </div>
    // <nav
    //   id="header"
    //   style={{ zIndex: 998 }}
    //   className="fixed top-0 w-full bg-base-100 text-base-content"
    // >
    //   <div className="mx-auto mt-0 flex w-full flex-wrap items-center justify-between py-2">
    //     <div className="flex flex-1 items-center">
    //       <LogoTitle hideLogo onClickRoute={'/app'} />
    //     </div>
    //     <div className="hidden flex-none md:flex">
    //       <div className="flex items-stretch">
    //         <div data-tip="Give Feedback" className="tooltip tooltip-bottom">
    //           <a
    //             onClick={openFeedback}
    //             className="btn btn-ghost btn-sm rounded-btn"
    //           >
    //             <svg
    //               xmlns="http://www.w3.org/2000/svg"
    //               viewBox="0 0 24 24"
    //               width="24"
    //               height="24"
    //             >
    //               <path fill="none" d="M0 0h24v24H0z" />
    //               <path
    //                 fill="white"
    //                 d="M6.455 19L2 22.5V4a1 1 0 0 1 1-1h18a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H6.455zM4 18.385L5.763 17H20V5H4v13.385zM11 13h2v2h-2v-2zm0-6h2v5h-2V7z"
    //               />
    //             </svg>
    //           </a>
    //         </div>
    //       </div>
    //     </div>
    //     <div className="hidden flex-none md:flex">
    //       <div className="flex items-stretch">
    //         <div data-tip="Help" className="tooltip tooltip-bottom">
    //           <a
    //             onClick={openHelp}
    //             className="btn btn-ghost btn-sm rounded-btn"
    //           >
    //             <svg
    //               xmlns="http://www.w3.org/2000/svg"
    //               viewBox="0 0 24 24"
    //               width="24"
    //               height="24"
    //             >
    //               <path fill="none" d="M0 0h24v24H0z" />
    //               <path
    //                 fill="white"
    //                 d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-5h2v2h-2v-2zm2-1.645V14h-2v-1.5a1 1 0 0 1 1-1 1.5 1.5 0 1 0-1.471-1.794l-1.962-.393A3.501 3.501 0 1 1 13 13.355z"
    //               />
    //             </svg>
    //           </a>
    //         </div>
    //       </div>
    //     </div>

    //     {/* <div className="flex-none hidden md:flex">
    //       <div className="flex items-stretch">
    //         <a
    //           target="_blank"
    //           href="https://reddit.com/r/limbiks"
    //           className="btn btn-ghost btn-sm rounded-btn"
    //         >
    //           <svg
    //             className="text-base-content"
    //             xmlns="http://www.w3.org/2000/svg"
    //             viewBox="0 0 24 24"
    //             width="24"
    //             height="24"
    //           >
    //             <path fill="none" d="M0 0h24v24H0z" />
    //             <path
    //               fill="white"
    //               d="M11.102 7.815l.751-3.536a2 2 0 0 1 2.373-1.54l3.196.68a2 2 0 1 1-.416 1.956l-3.196-.68-.666 3.135c1.784.137 3.557.73 5.163 1.7a3.192 3.192 0 0 1 4.741 2.673v.021a3.192 3.192 0 0 1-1.207 2.55 2.855 2.855 0 0 1-.008.123c0 3.998-4.45 7.03-9.799 7.03-5.332 0-9.708-3.024-9.705-6.953a5.31 5.31 0 0 1-.01-.181 3.192 3.192 0 0 1 3.454-5.35 11.446 11.446 0 0 1 5.329-1.628zm9.286 5.526c.408-.203.664-.62.661-1.075a1.192 1.192 0 0 0-2.016-.806l-.585.56-.67-.455c-1.615-1.098-3.452-1.725-5.23-1.764h-1.006c-1.875.029-3.651.6-5.237 1.675l-.663.45-.584-.55a1.192 1.192 0 1 0-1.314 1.952l.633.29-.054.695c-.013.17-.013.339.003.584 0 2.71 3.356 5.03 7.708 5.03 4.371 0 7.799-2.336 7.802-5.106a3.31 3.31 0 0 0 0-.508l-.052-.672.604-.3zM7 13.5a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0zm7 0a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0zm-1.984 5.103c-1.397 0-2.767-.37-3.882-1.21a.424.424 0 0 1 .597-.597c.945.693 2.123.99 3.269.99s2.33-.275 3.284-.959a.439.439 0 0 1 .732.206.469.469 0 0 1-.119.423c-.684.797-2.484 1.147-3.881 1.147z"
    //             />
    //           </svg>
    //         </a>
    //       </div>
    //     </div> */}

    //     <div className="dropdown-end dropdown">
    //       <div tabIndex={0} className="btn btn-ghost rounded-btn">
    //         {user?.email}
    //       </div>
    //       <ul
    //         tabIndex={0}
    //         className="dropdown-content menu rounded-box w-52 bg-gray-500 p-2 shadow"
    //       >
    //         <li>
    //           <a onClick={signOut}>Sign Out</a>
    //         </li>
    //       </ul>
    //     </div>
    //   </div>
    //   <hr className="my-0 border-b border-gray-100 py-0 opacity-25" />
    // </nav>
  );
}
