import React from 'react';
import AuthenticatedHeader from './authenticated-header/AuthenticatedHeader';
import { useAuth } from '@/app/context/AuthContext/useAuth';

type Props = {
  children: React.ReactNode;
};

const Authenticated = ({ children }: Props) => {
  const { user } = useAuth();

  if (!user) {
    return null;
  }

  return (
    <>
      <AuthenticatedHeader children={children}></AuthenticatedHeader>
    </>
  );
};

export default Authenticated;
