import React from 'react';
import AnonymousHeader from './new-anonymous-header/NewAnonymousHeader';
import Footer from './footer/Footer';
import BetaProgramCallout from '../../beta-program-callout/BetaProgramCallout';

type Props = {
  children: React.ReactNode;
  hidePricing?: boolean;
};

const Anonymous = ({ children, hidePricing }: Props) => (
  <>
    <AnonymousHeader />
    <div>{children}</div>
    {!hidePricing && <BetaProgramCallout />}
    <Footer />
  </>
);

export default Anonymous;
