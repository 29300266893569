import React from 'react';
import Authenticated from './authenticated/Authenicated';
import Anonymous from './anonymous/Anonymous';

interface IProps {
  authenticated?: boolean;
  hidePricing?: boolean;
  children: React.ReactNode;
}

export default function PageLayout(props: IProps) {
  if (props.authenticated) {
    return <Authenticated children={props.children} />;
  } else {
    return (
      <Anonymous hidePricing={props.hidePricing} children={props.children} />
    );
  }
}
